<template>
	<div class="mainlist">
		<div class="mainlistwarp">
			<el-link class="firsttitle" :href="plink">
				<img class="titleicon" src="../../../assets/images/Signsrecord.png" />
				体征记录
				<i class="el-icon-view el-icon-arrow-right"></i>
			</el-link>
			<el-link class="firsttitle" :href="mlink">
				<img class="titleicon" src="../../../assets/images/Medicalrecords.png" />
				就诊记录
				<i class="el-icon-view el-icon-arrow-right"></i>
			</el-link>
		</div>
	</div>
</template>

<script>
export default {
	name: 'gnrh',
	data() {
		return {
			plink : '',
			mlink : '',

			token: '',
			patientUuid: ''
		}
	},
	mounted() {
		this.token = this.$route.query.token;
		sessionStorage.setItem("token", this.token);
		this.patientUuid = this.$route.query.patientUuid;

		this.plink = '/#/gplist?patientUuid=' + this.patientUuid;
		this.mlink = '/#/gmlist?patientUuid=' + this.patientUuid;
	}
};
</script>

<style scoped>
	.mainlist {
		height: 100%;
		width: 100%;
		padding: 0;
		background-color: #e9eef7;
	}
	.mainlistwarp {
		padding: 0.875rem 0.625rem;
	}
	.titleicon {
		width: 1.625rem;
		height: 1.625rem;
		vertical-align: middle;
		margin-right: 0.625rem;
	}
	.firsttitle {
		font-size: 1rem;
		height: 3.75rem;
		line-height: 3.75rem;
		display: block;
		border-radius: 10px;
		background-color: #fff;
		margin-bottom: 0.875rem;
		padding: 0 0.9375rem;
		box-shadow: 0 0 0.3125rem #dedee1;
	}
	.el-link.el-link--default ,.el-link.el-link--default:hover{
		color: #4870ff;
	}
	.el-icon-arrow-right:before {
		color: #e0e0e0;
		font-size: 1.25rem;
		font-weight: bold;
	}
	.el-icon-view {
		float: right;
		line-height: inherit;
	}
	.el-link.is-underline:hover:after{
		border-bottom:0 none
	}
</style>
